import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/home/unlock/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/unlock/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/unlock/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"style\":[\"normal\"],\"display\":\"swap\",\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/unlock/unlock-app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/unlock/unlock-app/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/unlock/unlock-app/src/components/interface/layouts/DashboardLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/unlock/unlock-app/src/components/TagManagerScript.tsx");
